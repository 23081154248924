jQuery.fn.InitializeSearchPage = function (option) {
    var defaults = {
        initialJsonData: null,
        serviceUrl: null
    };

    var pageContent = $(this);
    if (pageContent.get(0) === undefined)
        return;
    var option = $.extend({}, defaults, option);
    var $filtersTrigger = $('.js-customize-filters-trigger');
    // Load JSON data from model
    var viewModelJs = JSON.parse(option.initialJsonData);
    var viewModel = ko.mapping.fromJS(viewModelJs);
    var guidEmpty = '00000000-0000-0000-0000-000000000000';
    var $scrollDefaultPosition = $('.js-scroll-default-position');
    var $scrollOffset = $('.homepage-navigation').height() * 10;
    var defaultScrollTop = $scrollDefaultPosition.offset() !== undefined ? $scrollDefaultPosition.offset().top : "";

    // Extend View Model
    viewModel.SelectedFilters = ko.observableArray();
    viewModel.IsSearchRunning = ko.observable(false);
    viewModel.HasSearchRun = ko.observable(false);
    viewModel.IsSelectAll = ko.observable(false);
    viewModel.IsClearAll = ko.observable(false);
    viewModel.HasPendingUpdates = ko.observable(false);
    viewModel.ScrollPositionUpdate = ko.observable(false);
    viewModel.ActivePageView = ko.observable("latest");
    viewModel.ActiveEventsPageView = ko.observable("upcomingevents");
    viewModel.ActivePracticeFilter = ko.observable();
    viewModel.ActiveArticleTypeFilter = ko.observable();

    viewModel.UpcomingEventsTake = ko.observable(viewModel.SearchFilters.InitialPageSize());

    viewModel.GetUpcomingEventPagination = ko.computed(function () {
        return viewModel.UpcomingEvents().slice(0, viewModel.UpcomingEventsTake());
    });

    viewModel.LoadNextUpcomingEventsPage = function () {
        var take = viewModel.UpcomingEventsTake() + viewModel.SearchFilters.Take();
        viewModel.UpcomingEventsTake(take);
    };

    viewModel.HasSelectedFilters = function () {

        var hasItem = ko.utils.arrayFirst(viewModel.SearchFilters.PracticeFilters(), function (item) {
            return (item.IsSelected() == true);
        });
        if (hasItem != null) {
            return true;
        }
        var hasItem = ko.utils.arrayFirst(viewModel.SearchFilters.IndustryFilters(), function (item) {
            return (item.IsSelected() == true);
        });
        if (hasItem != null) {
            return true;
        }
        var hasItem = ko.utils.arrayFirst(viewModel.SearchFilters.TypeFilters(), function (item) {
            return (item.IsSelected() == true);
        });
        if (hasItem != null) {
            return true;
        }
        var hasItem = ko.utils.arrayFirst(viewModel.SearchFilters.ArticleTypeFilters(), function (item) {
            return (item.IsSelected() == true);
        });
        if (hasItem != null) {
            return true;
        }
        var hasItem = ko.utils.arrayFirst(viewModel.SearchFilters.PublicationTypeFilters(), function (item) {
            return (item.IsSelected() == true);
        });

        if (hasItem != null) {
            return true;
        }
        var hasItem = ko.utils.arrayFirst(viewModel.SearchFilters.EventTypeFilters(), function (item) {
            return (item.IsSelected() == true);
        });

        if (hasItem != null) {
            return true;
        }
        var hasItem = ko.utils.arrayFirst(viewModel.SearchFilters.NewsTypeFilters(), function (item) {
            return (item.IsSelected() == true);
        });

        if (hasItem != null) {
            return true;
        }
        var hasItem = ko.utils.arrayFirst(viewModel.SearchFilters.BlogTypeFilters(), function (item) {
            return (item.IsSelected() == true);
        });

        if (hasItem != null) {
            return true;
        }
        var hasItem = ko.utils.arrayFirst(viewModel.SearchFilters.PastDateFilters(), function (item) {
            return (item.IsSelected() == true);
        });
        if (hasItem != null) {
            return true;
        }
        return false;
    }
    viewModel.DecodeHtmlInput = function (encodedString) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = encodedString;
        return textArea.value != null && textArea.value != "null" ? textArea.value : "";
    };
    viewModel.SearchFiltersSet = ko.computed(function () {
        return viewModel.HasSelectedFilters();
    });
    viewModel.SetActivePageView = function (activepage) {
        if (activepage !== "")
            viewModel.ActivePageView(activepage);
        else
            viewModel.ActivePageView("featured");
    };
    viewModel.SetActiveEventsPageView = function (activepage) {
        if (activepage !== "")
            viewModel.ActiveEventsPageView(activepage);
        else
            viewModel.ActiveEventsPageView("upcomingevents");
    };
    viewModel.IsVisibleList = ko.computed(function () {
        var activePage = viewModel.ActivePageView();
        var activeEventPage = viewModel.ActiveEventsPageView();
        var activeArticleFilter = viewModel.ActiveArticleTypeFilter();
        var searchFilterSet = viewModel.SearchFiltersSet();
        if (activeArticleFilter === "Events" && activeEventPage !== "upcomingevents") {
            return true;
        } else if (activeArticleFilter === "Events" && activeEventPage === "upcomingevents") {
            return false;
        } else if (activePage === "latest" || searchFilterSet === true) {
            return true;
        }
    });
    viewModel.IsActivePageView = function (activepage) {
        return (viewModel.ActivePageView() === activepage);
    };
    viewModel.IsActiveEventPageView = function (activepage) {
        return (viewModel.ActiveEventsPageView() === activepage);
    };
    viewModel.GetFirstCategory = function (inputstr) {
        if (inputstr !== "" && inputstr !== null && inputstr !== undefined) {
            var categoryTypes = inputstr.split("|");
            if (categoryTypes !== "" && categoryTypes.length !== 0)
                return categoryTypes[0];
        }
    };
    viewModel.GetCurrentArticleFilter = function () {
        var hasItem = ko.utils.arrayFirst(viewModel.SearchFilters.ArticleTypeFilters(), function (item) {
            return (item.IsSelected() == true);
        });
        return hasItem;
    };
    viewModel.HasOnlyArticleTypeFilter = function () {
        var filtersSet = true;
        for (var key in viewModel.SearchFilters) {
            if (viewModel.SearchFilters.hasOwnProperty(key) && filtersSet === false) {
                if (viewModel.SearchFilters[key]() !== null && viewModel.SearchFilters[key]() !== undefined && viewModel.SearchFilters[key]().length > 0 && key !== "ArticleTypeFilters") {
                    var rs = ko.utils.arrayFirst(viewModel.SearchFilters[key](), function (item) {
                        return (item.IsSelected() == true);
                    });
                    if (rs !== null)
                        filtersSet = false;
                }
            }
        }
    };

    // Build query string based on filters
    viewModel.QueryString = function (forceScrollPosition) {
        var queryString = "";
        var selectedItems = new Array();
        ko.utils.arrayForEach(viewModel.SearchFilters.PracticeFilters(), function (item) {
            if (item.IsSelected() == true) {
                selectedItems.push(item.ID());
            }
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.IndustryFilters(), function (item) {
            if (item.IsSelected() == true) {
                selectedItems.push(item.ID());
            }
        });
        if (selectedItems.length > 0) {
            queryString += (queryString != "" ? "&" : "?");
            queryString += "services=" + selectedItems.join();
        }
        selectedItems = new Array();
        ko.utils.arrayForEach(viewModel.SearchFilters.TypeFilters(), function (item) {
            if (item.IsSelected() == true) {
                selectedItems.push(item.ID());
            }
        });
        if (selectedItems.length > 0) {
            queryString += (queryString != "" ? "&" : "?");
            queryString += "types=" + selectedItems.join();
        }

        selectedItems = new Array();
        ko.utils.arrayForEach(viewModel.SearchFilters.PublicationTypeFilters(), function (item) {
            if (item.IsSelected() == true) {
                selectedItems.push(item.ID());
            }
        });
        if (selectedItems.length > 0) {
            queryString += (queryString != "" ? "&" : "?");
            queryString += "other=" + selectedItems.join();
        }

        selectedItems = new Array();
        ko.utils.arrayForEach(viewModel.SearchFilters.NewsTypeFilters(), function (item) {
            if (item.IsSelected() == true) {
                selectedItems.push(item.ID());
            }
        });
        if (selectedItems.length > 0) {
            queryString += (queryString != "" ? "&" : "?");
            queryString += "other=" + selectedItems.join();
        }

        selectedItems = new Array();
        ko.utils.arrayForEach(viewModel.SearchFilters.EventTypeFilters(), function (item) {
            if (item.IsSelected() == true) {
                selectedItems.push(item.ID());
            }
        });
        if (selectedItems.length > 0) {
            queryString += (queryString != "" ? "&" : "?");
            queryString += "other=" + selectedItems.join();
        }

        selectedItems = new Array();
        ko.utils.arrayForEach(viewModel.SearchFilters.BlogTypeFilters(), function (item) {
            if (item.IsSelected() == true) {
                selectedItems.push(item.ID());
            }
        });
        if (selectedItems.length > 0) {
            queryString += (queryString != "" ? "&" : "?");
            queryString += "other=" + selectedItems.join();
        }

        selectedItems = new Array();
        ko.utils.arrayForEach(viewModel.SearchFilters.PastDateFilters(), function (item) {
            if (item.IsSelected() == true) {
                selectedItems.push(item.ID());
                var dateArray = item.Url().split("|");
                if (!!dateArray && dateArray.length == 2) {
                    viewModel.SearchFilters.DateFrom(dateArray[0])
                    viewModel.SearchFilters.DateTo(dateArray[1])
                }
            }
        });
        if (selectedItems.length > 0) {
            queryString += (queryString != "" ? "&" : "?");
            queryString += "date=" + selectedItems.join();
        } else {
            viewModel.SearchFilters.DateFrom("")
            //viewModel.SearchFilters.DateTo("")
        }
        if (!!viewModel.SearchFilters.DateFrom() && viewModel.SearchFilters.DateFrom().length > 0) {
            queryString += (queryString != "" ? "&" : "?");
            queryString += "datefrom=" + viewModel.SearchFilters.DateFrom();
        }
        if (!!viewModel.SearchFilters.DateTo() && viewModel.SearchFilters.DateTo().length > 0) {
            queryString += (queryString != "" ? "&" : "?");
            queryString += "dateto=" + viewModel.SearchFilters.DateTo();
        }

        selectedItems = new Array();
        ko.utils.arrayForEach(viewModel.SearchFilters.ArticleTypeFilters(), function (item) {
            if (item.IsSelected() == true) {
                if (item.ID() !== guidEmpty) {
                    selectedItems.push(item.ID());
                } else {
                    //All Filter selected - Clear search filters
                    viewModel.SearchFilters.ClearSelectedFilters();
                    return;
                }
            }
        });

        if (selectedItems.length > 0) {
            queryString += (queryString != "" ? "&" : "?");
            queryString += "articletypes=" + selectedItems.join();
        }

        if (viewModel.SearchFilters.Skip() > 0) {
            queryString += (queryString != "" ? "&" : "?");
            queryString += "skip=" + viewModel.SearchFilters.Skip();
        }
        queryString += (queryString != "" ? "&" : "?");
        queryString += "reload=" + viewModel.SearchFilters.ReloadFilters();
        queryString += (queryString != "" ? "&" : "?");

        if (!!forceScrollPosition && forceScrollPosition > 0) {
            queryString += "scroll=" + forceScrollPosition;
        } else {
            queryString += "scroll=" + $(window).scrollTop();
        }
        return queryString;
    };

    // Run search by pushing new state
    viewModel.Search = function () {
        var windowPosition = (viewModel.HasSelectedFilters()) ? $scrollDefaultPosition.offset().top : 1;

        if (viewModel.HasPendingUpdates() == false) {
            return;
        }

        if (!viewModel.HasSelectedFilters()) {
            $filtersTrigger.text($filtersTrigger.data('expanded-label'))
        }

        //viewModel.HasMoreResults(false);
        viewModel.SearchFilters.Skip(0);
        var qs = viewModel.QueryString(windowPosition);
        viewModel.ScrollPositionUpdate(false);
        var data = History.getState().data;
        data['qs'] = qs;
        History.replaceState(data, History.options.initialTitle, qs);
    }

    // Load next page
    viewModel.LoadNextPage = function () {
        if (viewModel.IsSearchRunning())
            return;
        viewModel.IsSearchRunning(true);
        viewModel.SearchFilters.Skip(viewModel.GridData().length);
        viewModel.LoadDataFromServer();
        var qs = viewModel.QueryString();
        viewModel.ScrollPositionUpdate(false);
        var data = History.getState().data;
        data['qs'] = qs;
        History.replaceState(data, History.options.initialTitle, qs);
    }

    // Load data from server
    viewModel.LoadDataFromServer = function () {
        var viewModelToSend = GetRequestObject(viewModel.SearchFilters);

        $.ajax({
            url: option.serviceUrl,
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: ko.mapping.toJSON(viewModelToSend),
            cache: false,
            success: function (data) {
                var results = ko.observableArray();
                var resultsUpcoming = ko.observableArray();
                if (viewModel.SearchFilters.Skip() == 0) {
                    viewModel.ResultCount(0);
                    viewModel.GridData.removeAll();
                    viewModel.UpcomingEvents.removeAll();
                }
                ko.mapping.fromJS(data.GridData, {}, results);
                ko.utils.arrayForEach(results(), function (item) {
                    viewModel.GridData.push(item);
                });

                ko.mapping.fromJS(data.UpcomingEvents, {}, resultsUpcoming);
                ko.utils.arrayForEach(resultsUpcoming(), function (item) {
                    viewModel.UpcomingEvents.push(item);
                });
                viewModel.ResultCount(data.ResultCount);
                viewModel.HasMoreResults(data.HasMoreResults);
                viewModel.SearchFilters.ReloadFilters(data.SearchFilters.ReloadFilters);
                viewModel.SetSelectedFilters();
                viewModel.HasPendingUpdates(false);
                viewModel.SearchFilters.Skip(0);
                viewModel.SearchFilters.Take(10);

            },
            error: function (xhr, textStatus, errorThrown) {
                console.log(xhr);
                console.log("textStatus : " + textStatus);
                console.log("errorThrown : " + errorThrown);
            },
            complete: function name(xhr, textStatus) {
                viewModel.IsSearchRunning(false);
            }
        });
    }

    // Animations for new lit items
    viewModel.animateGridInsert = function (elem) { if (elem.nodeType === 1) $(elem).hide().fadeIn(800) }
    viewModel.animateGridDelete = function (elem) { if (elem.nodeType === 1) $(elem).fadeOut(800, function () { $(elem).remove(); }) }

    // Clear all filters and run search
    viewModel.SearchFilters.ClearSelectedFilters = function () {
        viewModel.SearchFilters.ClearAllSearchFilters();
        viewModel.SelectedFilters.removeAll();
        viewModel.HasPendingUpdates(true);
        viewModel.Search();
    };

    // Clear all filters
    viewModel.SearchFilters.ClearAllSearchFilters = function (callBack) {
        viewModel.SearchFilters.ClearAdvancedSearchFilters(callBack);
    };

    viewModel.SearchFilters.ClearAdvancedSearchFilters = function (callBack) {
        ko.utils.arrayForEach(viewModel.SearchFilters.PracticeFilters(), function (item) {
            item.IsSelected(false);
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.IndustryFilters(), function (item) {
            item.IsSelected(false);
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.TypeFilters(), function (item) {
            item.IsSelected(false);
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.ArticleTypeFilters(), function (item) {
            item.IsSelected(false);
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.PublicationTypeFilters(), function (item) {
            item.IsSelected(false);
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.EventTypeFilters(), function (item) {
            item.IsSelected(false);
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.NewsTypeFilters(), function (item) {
            item.IsSelected(false);
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.BlogTypeFilters(), function (item) {
            item.IsSelected(false);
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.PastDateFilters(), function (item) {
            item.IsSelected(false);
        });
        viewModel.SearchFilters.DateFrom('');
        //viewModel.SearchFilters.DateTo('');
        if (!!callBack && $.isFunction(callBack)) {
            callBack();
        }
    };
    viewModel.ResetPracticeFilter = function (itemType) {
        switch (itemType) {
            case "practice":
                ko.utils.arrayForEach(viewModel.SearchFilters.PracticeFilters(), function (item) {
                    item.IsSelected(false);
                });
                break;
            case "industry":
                ko.utils.arrayForEach(viewModel.SearchFilters.IndustryFilters(), function (item) {
                    item.IsSelected(false);
                });
                break;
            case "publication":
                ko.utils.arrayForEach(viewModel.SearchFilters.PublicationTypeFilters(), function (item) {
                    item.IsSelected(false);
                });
                break;
            case "event":
                ko.utils.arrayForEach(viewModel.SearchFilters.EventTypeFilters(), function (item) {
                    item.IsSelected(false);
                });
                break;
            case "date":
                ko.utils.arrayForEach(viewModel.SearchFilters.PastDateFilters(), function (item) {
                    item.IsSelected(false);
                });
                break;
            case "news":
                ko.utils.arrayForEach(viewModel.SearchFilters.NewsTypeFilters(), function (item) {
                    item.IsSelected(false);
                });
                break;
            case "blog":
                ko.utils.arrayForEach(viewModel.SearchFilters.BlogTypeFilters(), function (item) {
                    item.IsSelected(false);
                });
                break;
            case "All":
                ko.utils.arrayForEach(viewModel.SearchFilters.PracticeFilters(), function (item) {
                    item.IsSelected(false);
                });
                ko.utils.arrayForEach(viewModel.SearchFilters.IndustryFilters(), function (item) {
                    item.IsSelected(false);
                });
                ko.utils.arrayForEach(viewModel.SearchFilters.PublicationTypeFilters(), function (item) {
                    item.IsSelected(false);
                });
                ko.utils.arrayForEach(viewModel.SearchFilters.EventTypeFilters(), function (item) {
                    item.IsSelected(false);
                });
                ko.utils.arrayForEach(viewModel.SearchFilters.NewsTypeFilters(), function (item) {
                    item.IsSelected(false);
                });
                ko.utils.arrayForEach(viewModel.SearchFilters.BlogTypeFilters(), function (item) {
                    item.IsSelected(false);
                });
                break;
        }
        viewModel.HasPendingUpdates(true);
        viewModel.Search();
    };

    // Set selected filters
    viewModel.SetSelectedFilters = function () {
        var practiceCount = 0;
        var officeCount = 0;
        var industryCount = 0;
        viewModel.SelectedFilters.removeAll();
        ko.utils.arrayForEach(viewModel.SearchFilters.PracticeFilters(), function (item) {
            if (item.IsSelected() == true) {
                viewModel.SelectedFilters.push(item);
            }
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.IndustryFilters(), function (item) {
            if (item.IsSelected() == true) {
                viewModel.SelectedFilters.push(item);
            }
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.TypeFilters(), function (item) {
            if (item.IsSelected() == true) {
                viewModel.SelectedFilters.push(item);
            }
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.ArticleTypeFilters(), function (item) {
            if (item.IsSelected() == true) {
                viewModel.SelectedFilters.push(item);
                viewModel.ActiveArticleTypeFilter(item.ParentName());
            }
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.PublicationTypeFilters(), function (item) {
            if (item.IsSelected() == true) {
                viewModel.SelectedFilters.push(item);
            }
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.EventTypeFilters(), function (item) {
            if (item.IsSelected() == true) {
                viewModel.SelectedFilters.push(item);
            }
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.NewsTypeFilters(), function (item) {
            if (item.IsSelected() == true) {
                viewModel.SelectedFilters.push(item);
            }
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.BlogTypeFilters(), function (item) {
            if (item.IsSelected() == true) {
                viewModel.SelectedFilters.push(item);
            }
        });
        ko.utils.arrayForEach(viewModel.SearchFilters.PastDateFilters(), function (item) {
            if (item.IsSelected() == true) {
                viewModel.SelectedFilters.push(item);
            }
        });
    }
    window.onstatechange = function () {
        if (viewModel.IsSearchRunning())
            return;
        var currentState = History.getState();
        var qs = History.getState().data['qs'];
        var qsParms = !!qs ? parseQueryString(qs) : {};

        var currentSkip = viewModel.GridData().length;
        var newSkip = 0;
        var filterCount = 0;
        if (!!qsParms.skip && qsParms.skip > 0) {
            newSkip = parseInt(qsParms.skip);
        }
        var projectedTotal = newSkip + viewModel.SearchFilters.Take();
        if (projectedTotal < currentSkip && newSkip != 0) {
            // This is here for a back button after a page scroll
            viewModel.IsSearchRunning(true);
            viewModel.HasMoreResults(true);
            viewModel.HasSearchRun(true);
            viewModel.SearchFilters.Skip(newSkip);
            while (viewModel.GridData().length > projectedTotal) {
                var popped = viewModel.GridData.pop();
            }
            viewModel.IsSearchRunning(false);
        } else {
            // New filters have been selected so run search
            var existingFiltersTypeFilters = new Array();
            if (!!qsParms.articletypes && qsParms.articletypes.length > 0) {
                ko.utils.arrayForEach(viewModel.SearchFilters.ArticleTypeFilters(), function (item) {
                    var output = {
                        ID: item.ID(),
                        Filters: item.Filter(),
                        IsSelected: item.IsSelected(),
                        Name: item.Name()
                    };
                    existingFiltersTypeFilters.push(output);
                });
            }
            viewModel.SearchFilters.ClearAllSearchFilters();
            var hasFilters = false;
            var reloadFilters = false;
            var containsAdditionalFilters = false;

            if (!!qsParms.reload && qsParms.reload.length > 0) {
                reloadFilters = (qsParms.reload == "true");
            }
            viewModel.SearchFilters.ReloadFilters(reloadFilters);
            if (!!qsParms.keyword && qsParms.keyword.length > 0) {
                viewModel.SearchFilters.KeywordFilter(qsParms.keyword);
                hasFilters = true;
                containsAdditionalFilters = true;
            }
            viewModel.SearchFilters.Skip(newSkip);

            if (!!qsParms.services && qsParms.services.length > 0) {
                ko.utils.arrayForEach(viewModel.SearchFilters.PracticeFilters(), function (item) {
                    if (qsParms.services.indexOf(item.ID()) >= 0) {
                        if (!(item.ID() === '0a774288-0858-454b-af7c-1614bd4680df' && lastChangedType === 'IndustryFilter')) {
                            debugger;
                            item.IsSelected(true);
                            hasFilters = true;
                            containsAdditionalFilters = true;
                        }
                    }
                });
                ko.utils.arrayForEach(viewModel.SearchFilters.IndustryFilters(), function (item) {
                    if (qsParms.services.indexOf(item.ID()) >= 0) {
                        if (!(item.ID() === '0a774288-0858-454b-af7c-1614bd4680df' && lastChangedType === 'ServiceFilters')) {
                            item.IsSelected(true);
                            hasFilters = true;
                            containsAdditionalFilters = true;
                        }
                    }
                });
            }
            if (!!qsParms.types && qsParms.types.length > 0) {
                ko.utils.arrayForEach(viewModel.SearchFilters.TypeFilters(), function (item) {
                    if (qsParms.types.indexOf(item.ID()) >= 0) {
                        item.IsSelected(true);
                        hasFilters = true;
                    }
                });
            }
            if (!!qsParms.other && qsParms.other.length > 0) {
                ko.utils.arrayForEach(viewModel.SearchFilters.PublicationTypeFilters(), function (item) {
                    if (qsParms.other.indexOf(item.ID()) >= 0) {
                        item.IsSelected(true);
                        hasFilters = true;
                        containsAdditionalFilters = true;
                    }
                });
                ko.utils.arrayForEach(viewModel.SearchFilters.NewsTypeFilters(), function (item) {
                    if (qsParms.other.indexOf(item.ID()) >= 0) {
                        item.IsSelected(true);
                        hasFilters = true;
                        containsAdditionalFilters = true;
                    }
                });
                ko.utils.arrayForEach(viewModel.SearchFilters.EventTypeFilters(), function (item) {
                    if (qsParms.other.indexOf(item.ID()) >= 0) {
                        item.IsSelected(true);
                        hasFilters = true;
                        containsAdditionalFilters = true;
                    }
                });
                ko.utils.arrayForEach(viewModel.SearchFilters.BlogTypeFilters(), function (item) {
                    if (qsParms.other.indexOf(item.ID()) >= 0) {
                        item.IsSelected(true);
                        hasFilters = true;
                        containsAdditionalFilters = true;
                    }
                });
            }
            if (!!qsParms.articletypes && qsParms.articletypes.length > 0) {
                ko.utils.arrayForEach(viewModel.SearchFilters.ArticleTypeFilters(), function (item) {
                    ko.utils.arrayForEach(existingFiltersTypeFilters, function (obj) {
                        if (item.ID() === obj.ID && item.Name() === obj.Name && obj.IsSelected === true) {
                            item.IsSelected(true);
                        }
                    });
                });
            }
            if (!!qsParms.date && qsParms.date.length > 0) {
                ko.utils.arrayForEach(viewModel.SearchFilters.PastDateFilters(), function (item) {
                    if (qsParms.date.indexOf(item.ID()) >= 0) {
                        item.IsSelected(true);
                        hasFilters = true;
                        containsAdditionalFilters = true;
                    }
                });
            }

            if (!!qsParms.date && qsParms.date.length > 0) {
                ko.utils.arrayForEach(viewModel.SearchFilters.FutureDateFilters(), function (item) {
                    if (qsParms.date.indexOf(item.ID()) >= 0) {
                        item.IsSelected(true);
                        hasFilters = true;
                        containsAdditionalFilters = true;
                    }
                });
            }

            if (!!qsParms.datefrom && qsParms.datefrom.length > 0) {
                viewModel.SearchFilters.DateFrom(qsParms.datefrom);
            }

            if (!!qsParms.dateto && qsParms.dateto.length > 0) {
                viewModel.SearchFilters.DateTo(qsParms.dateto);
            }

            if (!viewModel.ScrollPositionUpdate()) {
                viewModel.LoadDataFromServer();
                viewModel.HasSearchRun(hasFilters);
            }

            if (containsAdditionalFilters === true) {
                viewModel.DisplaySetFilters(containsAdditionalFilters);
            }
        }
        return;
    }
    // Set filters that might have been included from a back button
    viewModel.SetSelectedFilters();

    // Apply Bindings
    ko.applyBindings(viewModel, pageContent.get(0));

    // Check for scroll in query string
    var qsParms = parseQueryString(window.location.href);
    var scrollPosition = 0;
    if (!!qsParms.scroll && qsParms.scroll.length > 0) {
        scrollPosition = parseInt(qsParms.scroll);
    }

    if (!!qsParms.articletypes && qsParms.articletypes.length > 0) {
        ko.utils.arrayForEach(viewModel.SearchFilters.ArticleTypeFilters(), function (item) {
            if (item.ID() === qsParms.articletypes) {
                if (!!qsParms.skip && qsParms.skip > 0) {
                    if (parseInt(qsParms.scroll) !== NaN) {
                        viewModel.SearchFilters.Take(parseInt(parseInt(qsParms.skip) + 10));
                    }
                }
                item.IsSelected(true);
                viewModel.LoadDataFromServer();
            }
        });
    }
    if (viewModel.SelectedFilters().length > 0) {
        viewModel.HasSearchRun(true);
        viewModel.IsSelectAll(false);
        viewModel.IsClearAll(false);
    } else {
        viewModel.IsSelectAll(true);
        viewModel.IsClearAll(false);
        var searchCriteriaCookie = $.cookie('_home_page_search_critera');
        if (!!searchCriteriaCookie) {
            viewModel.ScrollPositionUpdate(false);
            History.pushState({
                qs: searchCriteriaCookie
            }, History.options.initialTitle, searchCriteriaCookie);
        }
    }
};